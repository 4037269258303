// 
// user.js
// Use this to write your custom JS
//

jQuery(document).ready(function ($) {
    // var src = $("#videoFrame").attr('src'); 
    // var visible = $("#videoFrame").element
    
    // ("display"); 
    // if(visible != 'none'){
    //     alert("1");
    //  $("#videoHinweis").css("display: none;");
    // }
    // else{
    //     alert("2");
    // }

    // if($("#videoFrame").is(":hidden")){
    //     alert("The paragraph  is visible.");
    // } else{
    //     alert("The paragraph  is hidden.");
    // }

    

    // alert($('#videoFrame').css('display'));

    if(!$('#videoFrame').css('display') == 'none')
    {
        // $("#videoHinweis").css("display: none;");
        // $('#videoHinweis').hide();
        alert("The paragraph  is hidden.");
        $("#videoHinweis").css("display", "none");
    }

    if($('#videoFrame:visible').length == 0)
    {
        $("#videoHinweisQuelle").css("display", "none");
    } 
    else
    {
        $("#videoHinweis").css("display", "none");
    }
 });

 document.addEventListener("DOMContentLoaded", () => {

    // alert(document.getElementById("videoHinweis").style.display);
    // alert(document.getElementById("videoHinweis").innerHTML);


  });